import React, { useState, useEffect } from "react";

import AddIcon from "@material-ui/icons/Add";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { useMutation } from "@apollo/client";

import { UPDATE_PRODUCT } from "../../utils/queries";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useRecoilValue } from "recoil";
import { PRODUCT_VALUES, CATEGRORIES_TYPE } from "../../recoil/atoms";
import { useCategories } from "../../hooks/useCategories";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const ProductToEdit = () => {
  const { id, ...productToEdit } = useRecoilValue(PRODUCT_VALUES);

  const ACTIVITY_ID =
    localStorage.getItem("ACTIVITY_ID_TOKEN") ||
    cookies.get("ACTIVITY_ID_TOKEN");

  const type = useRecoilValue(CATEGRORIES_TYPE);

  const categories = useCategories(type, ACTIVITY_ID);

  const [name, setName] = useState(productToEdit.name || "");
  const [price, setPrice] = useState(productToEdit.price || "");
  const [category, setCategory] = useState(productToEdit.category.id || "");
  const [description, setDescription] = useState(
    productToEdit.description || ""
  );
  const [isRecommended, setIsRecommended] = useState(
    productToEdit.isRecommended || false
  );

  const [optionPrice, setOptionPrice] = useState("");
  const [optionValue, setOptionValue] = useState("");

  const [options, setOptions] = useState(productToEdit.options || []);

  const [isOpenMessage, setOpenMessage] = useState(false);

  const [imageId] = useState("");

  const [isDisabled, setDisabled] = useState(true);

  const [setProductDatabase] = useMutation(UPDATE_PRODUCT, {
    variables: {
      id: id,
      name: name,
      image: imageId,
      description: description,
      price: price,
      category: category,
      options: options,
      isRecommended: isRecommended,
    },
  });

  const handleInsertOptions = () => {
    let newOption = {
      name: optionValue || "",
      price: optionPrice || 0,
    };
    setOptionValue("");
    setOptionPrice("");
    setOptions([...options, newOption]);
  };

  const handleRemove = (index) => {
    let tempOptions = JSON.parse(JSON.stringify(options));
    tempOptions.splice(index, 1);
    setOptions(tempOptions);
  };

  const handleInsert = () => {
    setProductDatabase()
      .then(() => window.location.reload())
      .catch(() => {
        setOpenMessage(true);
      });
  };

  const handleRecommendedChange = (event) =>
    setIsRecommended(event.target.checked);

  useEffect(() => {
    setDisabled(
      name.trim() === "" ||
        category.trim() === "" ||
        description.trim() === "" ||
        price === ""
    );
  }, [name, price, description, options, category]);

  return (
    <>
      <Snackbar
        open={isOpenMessage}
        autoHideDuration={900}
        onClose={() => setOpenMessage(false)}
        message={"Ci sono stati errori, riprova più tardi."}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />
      <List>
        <ListItem>
          <TextField
            style={{ margin: 8 }}
            label="Nome prodotto"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            style={{ margin: 8 }}
            label="Prezzo prodotto"
            type="number"
            variant="outlined"
            value={price}
            onChange={(e) =>
              e.target.value === ""
                ? setPrice("")
                : setPrice(e.target.valueAsNumber)
            }
            inputProps={{ step: "0.1", min: "0" }}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <TextField
            style={{ margin: 8 }}
            label="Descrizione prodotto"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            style={{ margin: 8, width: "100%" }}
            control={
              <Checkbox
                checked={isRecommended}
                onChange={handleRecommendedChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Consigliato"
          />
        </ListItem>
        <ListItem>
          <Box style={{ margin: 8, maxWidth: "50%", width: "100%" }}>
            <InputLabel htmlFor="standard-adornment">
              Voci aggiuntive (taglie, pesi, colori, ecc.)
            </InputLabel>
            <Box style={{ display: "flex", margin: 8 }}>
              <TextField
                style={{ marginLeft: 8, marginRight: 8 }}
                label="Tipologia"
                value={optionValue}
                onChange={(e) => setOptionValue(e.target.value)}
              />
              <TextField
                style={{ marginLeft: 8, marginRight: 8 }}
                label="Prezzo"
                type="number"
                value={optionPrice}
                onChange={(e) =>
                  e.target.value === ""
                    ? setOptionPrice("")
                    : setOptionPrice(e.target.valueAsNumber)
                }
                inputProps={{ step: "0.1", min: "0" }}
              />
              <IconButton
                onClick={() => handleInsertOptions()}
                disabled={
                  optionValue.length === 0 ||
                  optionPrice.toString().length === 0
                    ? true
                    : false
                }
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Box style={{ padding: 8, display: "flex", overflowX: "auto" }}>
              {options.map(({ name, price }, index) => (
                <Box style={{ margin: 8 }}>
                  <Chip
                    key={index}
                    label={`${name} - ${price.toFixed(2)}€`}
                    onDelete={() => handleRemove(index)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <Box style={{ width: "100%" }}>
            <InputLabel>Categoria</InputLabel>
            <Select
              style={{ margin: 8 }}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label="Categoria"
              fullWidth
            >
              {categories.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </Box>
        </ListItem>
        <Box
          style={{ margin: 16, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<SaveIcon />}
            disabled={isDisabled}
            onClick={() => handleInsert()}
          >
            Salva
          </Button>
        </Box>
      </List>
    </>
  );
};

export default ProductToEdit;
