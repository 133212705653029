import React, { useState, useEffect } from "react";

import { Header } from "./components/Header";
import FunctionPage from "./components/FunctionPage";
import OrderStatus from "./components/orders/OrderStatus";
import General from "./pages/General";
import Settings from "./pages/Settings";
import Feedback from "./pages/Feedback";
import Help from "./pages/Help";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import ViewListIcon from "@material-ui/icons/ViewList";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { createUploadLink } from "apollo-upload-client";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import SignIn from "./pages/Login";
import { PrivateRoute } from "./utils/PrivateRoute";
import Cookies from "universal-cookie";

import { useRecoilValue } from "recoil";
import { JWT as JWTAtom } from "./recoil/atoms";

const cookies = new Cookies();

const App = () => {
  const ACTIVITY_ID =
    localStorage.getItem("ACTIVITY_ID_TOKEN") ||
    cookies.get("ACTIVITY_ID_TOKEN");

  const jwt = useRecoilValue(JWTAtom);

  const client = new ApolloClient({
    link: createUploadLink({
      uri: "https://cms.appmybusiness.eu/graphql",
      headers: !!jwt
        ? {
            Authorization: `Bearer ${jwt}`,
          }
        : null,
    }),
    cache: new InMemoryCache(),
  });

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ApolloProvider client={client}>
          <Router>
            <Switch>
              <Route exact path="/"></Route>
              <Route path="/login" />
              <Route path="/">
                <Header funzioni={functions} />
              </Route>
            </Switch>
            <Switch>
              <Route path="/login" exact>
                <SignIn />
              </Route>
              <PrivateRoute
                children={() => (
                  <OrderStatus
                    name={"Stato ordini"}
                    ACTIVITY_ID={ACTIVITY_ID}
                  />
                )}
                path="/status"
              />
              <PrivateRoute
                path="/ordinations"
                exact
                children={() => (
                  <FunctionPage
                    name={"Ordinazioni"}
                    functionType={"602e24bdaf346611eb4bde32"}
                    ACTIVITY_ID={ACTIVITY_ID}
                  />
                )}
              />
              <PrivateRoute
                path="/ecommerce"
                exact
                children={() => (
                  <FunctionPage
                    name={"ECommerce"}
                    functionType={"602e24b4af346611eb4bde31"}
                    ACTIVITY_ID={ACTIVITY_ID}
                  />
                )}
              />
              <PrivateRoute
                path="/services"
                exact
                children={() => (
                  <FunctionPage
                    name={"Servizi"}
                    functionType={"602e24c3af346611eb4bde33"}
                    ACTIVITY_ID={ACTIVITY_ID}
                  />
                )}
              />
              <PrivateRoute
                path="/generals"
                exact
                children={() => <General />}
              />
              <PrivateRoute
                path="/settings"
                exact
                children={() => <Settings />}
              />
              <PrivateRoute path="/help" exact children={() => <Help />} />
              <PrivateRoute
                path="/feedback"
                exact
                children={() => <Feedback />}
              />
              <PrivateRoute children={() => <Redirect to={"/status"} />} />
            </Switch>
          </Router>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default App;

const functions = [
  {
    key: "status",
    name: "Stato",
    icon: <ViewListIcon />,
    to: "/status",
  },
  {
    key: "ordinations",
    name: "Ordinazioni",
    icon: <MenuBookIcon />,
    to: "/ordinations",
  },
  {
    key: "ecommerce",
    name: "ECommerce",
    icon: <ShoppingCartIcon />,
    to: "/ecommerce",
  },
  {
    key: "services",
    name: "Servizi",
    icon: <RoomServiceIcon />,
    to: "/services",
  },
];
