import React, { useState, useEffect } from "react"
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField'
import Box from "@material-ui/core/Box"
import Button from '@material-ui/core/Button'
import SaveIcon from "@material-ui/icons/Save"
import Snackbar from '@material-ui/core/Snackbar';

import { useMutation } from '@apollo/client'

import { UPDATE_CATEGORY } from "../../utils/queries"

import { useRecoilValue } from "recoil"
import { CATEGRORIES_TYPE, CATEGORY_VALUES } from "../../recoil/atoms"

const CategoryToEdit = () => {

    const type = useRecoilValue(CATEGRORIES_TYPE)
    const { id, ...categoryToEdit } = useRecoilValue(CATEGORY_VALUES)

    const [name, setName] = useState(categoryToEdit.name || '')

    const [isDisabled, setDisabled] = useState(true)
    const [isOpenMessage, setOpenMessage] = useState(false)

    const [setCategoryDatabase] = useMutation(UPDATE_CATEGORY, {
        variables: {
            id: id,
            name: name,
        }
    })

    const handleUpdate = () => {
        setCategoryDatabase()
            .then(() =>
                window.location.reload())
            .catch(() => {
                setOpenMessage(true)
            })
    }

    useEffect(() => {
        setDisabled(name.trim() === '')
    }, [name])

    return (
        <>
            <Snackbar open={isOpenMessage} autoHideDuration={900} onClose={() => setOpenMessage(false)} message={"Ci sono stati errori, riprova più tardi."} anchorOrigin={{ horizontal: "center", vertical: "top" }} />
            < List >
                <ListItem>
                    <TextField id="outlined-basic" label="Nome categoria" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
                </ListItem>
                <Box style={{ margin: 16, display: "flex", justifyContent: "flex-end" }} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        endIcon={<SaveIcon />}
                        disabled={isDisabled}
                        onClick={() => handleUpdate()}
                    >
                        Salva
                    </Button>
                </Box>
            </List >
        </>
    )
}

export default CategoryToEdit