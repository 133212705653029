import { atom } from "recoil";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export const PRODUCT_VALUES = atom({
  key: "PRODUCT_VALUES",
  default: {
    id: "",
    name: "",
    price: "",
    description: "",
    category: "",
    options: [],
    isRecommended: false,
  },
});

export const CATEGORY_VALUES = atom({
  key: "CATEGOR_VALUES",
  default: {
    id: "",
    name: "",
  },
});

export const CATEGRORIES_TYPE = atom({
  key: "CATEGRORIES_TYPE",
  default: "",
});

export const JWT = atom({
  key: "JWT",
  default: localStorage.getItem("ACCESS_TOKEN") || cookies.get("ACCESS_TOKEN"),
});

export const ID = atom({
  key: "ID",
  default: "",
});
