import React, { useState, useEffect } from "react"

import Box from "@material-ui/core/Box"
import Grid from '@material-ui/core/Grid'
import Button from "@material-ui/core/Button"
import Alert from "@material-ui/lab/Alert"

import PasswordInput from "../components/PasswordInput"
import SaveIcon from "@material-ui/icons/Save"

import { EDIT_PSW } from "../utils/queries"
import { useMutation } from '@apollo/client'
import { settings } from "../utils/labels.json"

const FirstAccessEdit = ({ ACTIVITY_ID }) => {

    const [setPsw] = useMutation(EDIT_PSW)

    const [isPasswordValid, setPasswordValid] = useState(true)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [isPasswordError, setPasswordError] = useState(false)

    const PaddedAlert = ({ text, type, condition }) => condition && <div style={{ margin: 8 }} ><Alert severity={type} >{text}</Alert></div>

    useEffect(() => {
        if (password.length >= 6 && password === confirmPassword)
            setPasswordValid(true)
        else
            setPasswordValid(false)
    }, [password, confirmPassword])

    const SaveButton = ({ disabled = false, onClick }) => <Box style={{ marginTop: 32, display: "flex", justifyContent: "flex-end" }} >
        <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<SaveIcon />}
            disabled={disabled}
            onClick={onClick}
        >
            Salva
        </Button>
    </Box>

    const handleEditPsw = (password) => {
        setPsw({ variables: { key: password, user: ACTIVITY_ID } })
            .then(() => {
                window.open('/status', '_self')
            })
            .catch(() => {
                setPasswordError(true)
            })
    }

    return (
        <>
            <Grid style={{ marginBottom: 8, marginTop: 8 }} container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <PasswordInput value={password} onChange={(e) => setPassword(e.target.value)} placeholder={settings.password.newPas} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PasswordInput value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder={settings.password.confPas} />
                </Grid>
            </Grid>
            <PaddedAlert type={"error"} text={settings.password.errors} condition={isPasswordError} />
            <PaddedAlert type={"warning"} text={settings.password.pattern} condition={password.length < 6} />
            <PaddedAlert type={"warning"} text={settings.password.warning} condition={password !== confirmPassword} />

            <SaveButton onClick={() => handleEditPsw(password)} disabled={!isPasswordValid} />
        </>
    )
}

export default FirstAccessEdit