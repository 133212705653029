import React, { useState, useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import Cookies from "universal-cookie"

import jwt_decode from "jwt-decode"

import { useRecoilState } from "recoil"
import { ID as IDAtom } from "../recoil/atoms"

const cookies = new Cookies()
export const PrivateRoute = ({ children: Component, ...rest }) => {

    const [auth, setAuth] = useState(false);
    const [isTokenValidated, setIsTokenValidated] = useState(false);

    const [idActivity, setIdActivity] = useRecoilState(IDAtom)

    useEffect(() => {
        let token = localStorage.getItem("ACCESS_TOKEN") || cookies.get('ACCESS_TOKEN');
        if (token) {
            let current_time = Date.now() / 1000;

            let decodedToken = jwt_decode(token)
            if (decodedToken.exp > current_time) {
                setAuth(true)
                setIdActivity(decodedToken.id)
            }
            setIsTokenValidated(true);
        } else {
            setIsTokenValidated(true);
        }

    }, [])

    if (!isTokenValidated) return <div />;

    return (<Route {...rest}
        render={(props) => {
            return auth ? <Component {...props} /> : <Redirect to="/login" />
        }} />)
}