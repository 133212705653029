import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const Account = ({ children, name }) => {
    return (
        <Container style={{ margin: 16 }}>
            <Typography variant="h4" component="h2" gutterBottom style={{ fontWeight: "bold" }}>{name}</Typography>
            {children}
        </Container >
    )
}

export default Account