import React, { useState } from "react"

import Container from "@material-ui/core/Container"
import Fab from "@material-ui/core/Fab"
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box"
import Typography from '@material-ui/core/Typography';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import noData from "../../assets/noData.png"


import { ORDER_COLS } from "../../utils/columns"

import { useOrders } from "../../hooks/useOrders"
import { useMutation, gql } from '@apollo/client'
import ModalProducts from "./ModalProducts"

const UPDATE_STATUS = gql`
mutation (
    $id:ID!
    $status:String!
  ) {
    updateOrder (
      input: {
        where: {id: $id}
        data: {
          status: $status
        }
      }
    ) {
      order {
        id
      }
    }
  }
`

const TableOrders = ({ delivered = false, ACTIVITY_ID }) => {

    const orders = useOrders(delivered, ACTIVITY_ID)

    const [updateStatus,] = useMutation(UPDATE_STATUS)
    const [isDialogOpen, setDialogOpen] = useState(false)
    const [orderProducts, setOrderProduct] = useState([])

    const [orderNote, setNotes] = useState('')


    let columns = []

    if (!delivered) {
        columns = [
            ...ORDER_COLS,
            {
                key: "pickupday",
                name: "Giorno del ritiro"
            },
            {
                key: "before",
                name: "Indietro"
            },
            {
                key: "status",
                name: "Stato"
            },
            {
                key: "next",
                name: "Avanti"
            },
        ]
    } else {
        columns = ORDER_COLS
    }

    function getNextStatus(status) {
        switch (status) {
            case 'I':
                status = 'P'
                break;
            case 'P':
                status = 'S'
                break;
            case 'S':
                status = 'C'
                break;
            default:
                status = 'I'
                break;
        }
        return status
    }

    function getPreviousStatus(status) {
        switch (status) {
            case 'P':
                status = 'I'
                break;
            case 'S':
                status = 'P'
                break;
            default:
                status = 'I'
                break;
        }
        return status
    }

    function extendStatusName(status) {
        switch (status) {
            case 'I':
                status = 'Inoltrato'
                break;
            case 'P':
                status = 'Lavorazione'
                break;
            case 'S':
                status = 'Spedito'
                break;
            case 'C':
                status = 'Consegnato'
                break;
            default:
                status = 'Inoltrato'
                break;
        }
        return status
    }

    const handleNextState = (id, status) => {
        status = getNextStatus(status)
        updateStatus({ variables: { id: id, status: status } })
            .then(() => window.location.reload())
            .catch(() => alert('Errore'))
    }

    const handlePreviousState = (id, status) => {
        status = getPreviousStatus(status)
        updateStatus({ variables: { id: id, status: status } })
            .then(() => window.location.reload())
            .catch(() => alert('Errore'))
    }


    function dateDDMMYY(timestamp) {
        let date = new Date(timestamp)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`
    }

    function timeMMHH(timestamp) {
        let time = new Date(timestamp)
        let minutes = time.getMinutes()
        let hours = time.getHours()
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
    }

    const handleProductsShow = (orders_details, notes) => {
        setNotes(notes)
        setOrderProduct(orders_details)
        setDialogOpen(true)
    }

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(({ name, key }) =>
                                    <TableCell style={{ textAlign: 'center' }} key={key}>{name}</TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} fullWidth >
                            {
                                orderNote !== null && <Box style={{ margin: 16 }}>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        Note aggiuntive:
                                </Typography>
                                    <Typography>
                                        {orderNote}
                                    </Typography>
                                </Box>}
                            <Typography style={{ fontWeight: 'bold', margin: 16 }}>Prodotti ordinati: </Typography>
                            <div style={{ display: "flex", flexDirection: "column" }} >
                                {
                                    orderProducts.map(({ product: { Name, Image: { url }, Price }, quantity, option }) => {
                                        let productPrice
                                        let name = undefined
                                        if (option === null)
                                            productPrice = Price
                                        else {
                                            productPrice = option.price
                                            name = option.name
                                        }
                                        return <ModalProducts productName={Name} productImage={`http://cms.appmybusiness.eu${url}`} productQuantity={quantity} productOption={name} productPrice={productPrice} />
                                    })
                                }
                            </div>
                        </Dialog>
                        {
                            orders.length === 0 ? <TableRow >
                                <img src={noData} width={300} height={300} alt="Dati vuoti" />
                            </TableRow> : orders.map(({ id, name, address, city, phone, total, status, createdAt, orderId, orders_details, notes, pickup, pickupDay }) => {
                                let newStatus = extendStatusName(status)
                                return (
                                    <TableRow key={id} onClick={() => handleProductsShow(orders_details, notes)}>
                                        <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>{orderId.substr(-6).toUpperCase()}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{name}</TableCell>
                                        {
                                            pickup ? <> <TableCell style={{ textAlign: 'center' }}>IN SEDE</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>-</TableCell> </> : <> <TableCell style={{ textAlign: 'center' }}>{address}</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>{city}</TableCell> </>
                                        }
                                        <TableCell style={{ textAlign: 'center' }}>{phone}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{dateDDMMYY(createdAt)} {timeMMHH(createdAt)}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{total}€</TableCell>
                                        {
                                            !delivered && <>
                                                <TableCell style={{ textAlign: 'center' }}>{pickup ? dateDDMMYY(pickupDay) : '-'}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <Fab onClick={() => handlePreviousState(id, status)} disabled={status === 'I'} variant="extended" color="default" aria-label="edit" size="small" >
                                                        <NavigateBeforeIcon />
                                                    </Fab>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{newStatus}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <Fab onClick={() => handleNextState(id, status)} disabled={status === "C"} variant="extended" color="default" aria-label="edit" size="small" >
                                                        {extendStatusName(getNextStatus(status))}
                                                        <NavigateNextIcon />
                                                    </Fab>
                                                </TableCell>
                                            </>
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default TableOrders