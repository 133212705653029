import React, { useState } from "react"

import Fab from "@material-ui/core/Fab"
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import Avatar from "@material-ui/core/Avatar"
import TableRow from '@material-ui/core/TableRow'
import Container from "@material-ui/core/Container"
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Typography from "@material-ui/core/Typography"
import TableContainer from '@material-ui/core/TableContainer'

import noData from "../../assets/noData.png"
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import ModalCategory from "./ModalCategory"

import { useRecoilState } from "recoil"
import { CATEGORY_VALUES } from "../../recoil/atoms"


import { useMutation, gql } from '@apollo/client'

const REMOVE_CATEGORY = gql`
mutation($id: ID!) {
    updateCategory(input: { where: { id: $id }, data: { Disabled: true } }) {
        category {
            id
        }
    }
}
`

const CategoryTable = ({ columns, rows, tableName }) => {

    const [id, setId] = useState('')
    const [isOpenModalAdd, setOpenModalAdd] = useState(false)
    const [isOpenModalEdit, setOpenModalEdit] = useState(false)

    const [, setCategoryValues] = useRecoilState(CATEGORY_VALUES)
    const [deleteCategory] = useMutation(REMOVE_CATEGORY, { variables: { id: id } })

    const handleDeleteCategory = (id) => {
        setId(id)
        setTimeout(() => {
            deleteCategory()
                .then(() => window.location.reload())
                .catch((error) => console.log(error))
        }, 300);
    }

    const handleEditCategory = (value, category) => {
        setCategoryValues(category)
        setOpenModalEdit(value)
    }

    return (
        <>
            <Container style={{ margin: 16 }}>
                <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                    <Typography variant="h5" component="h2" gutterBottom style={{ fontWeight: "bold" }}>{tableName}</Typography>
                    <Fab variant="extended" color="primary" aria-label="add" size="medium" onClick={() => setOpenModalAdd(true)} >
                        <AddIcon />
                        Nuova categoria
                    </Fab>
                </Grid>
                <ModalCategory isOpen={isOpenModalAdd} handleClose={() => setOpenModalAdd(false)} />
                <ModalCategory isOpen={isOpenModalEdit} handleClose={() => setOpenModalEdit(false)} edit />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    [...columns, { key: "edit", name: "Modifica" }, { key: "delete", name: "Elimina" }].map(({ name, key }) =>
                                        <TableCell key={key}>{name}</TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.length === 0 ? <TableRow >
                                    <img src={noData} width={300} height={300} alt="Dati vuoti" />
                                </TableRow> : rows.map(({ id, name, logo }, index) => {
                                    let category = {
                                        id, name
                                    }
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell><Avatar src={`http://cms.appmybusiness.eu${logo}`} /></TableCell>
                                            <TableCell>{name}</TableCell>
                                            <TableCell>
                                                <Fab color="default" aria-label="edit" size="small" onClick={() => handleEditCategory(true, category)} >
                                                    <EditIcon />
                                                </Fab>
                                            </TableCell>
                                            <TableCell>
                                                <Fab color="default" aria-label="edit" size="small" onClick={() => handleDeleteCategory(id)} >
                                                    <DeleteIcon />
                                                </Fab>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default CategoryTable