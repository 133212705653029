export const PRODUCT_COLS = [
    {
        key: "index",
        name: "#"
    },
    {
        key: "image",
        name: "Immagine"
    },
    {
        key: "name",
        name: "Nome"
    },
    {
        key: "price",
        name: "Prezzo"
    },
    {
        key: "category",
        name: "Categoria"
    },
    {
        key: "inOffer",
        name: "In offerta"
    },
    {
        key: "expiry",
        name: "Scadenza offerta"
    },
    {
        key: "offerPrice",
        name: "Prezzo offerta"
    },
]

export const CATEGORY_COLS = [
    {
        key: "index",
        name: "#"
    },
    {
        key: "image",
        name: "Immagine"
    },
    {
        key: "name",
        name: "Nome"
    },
]

export const ORDER_COLS = [
    {
        key: "id",
        name: "ID Ordine"
    },
    {
        key: "name",
        name: "Nome"
    },
    {
        key: "address",
        name: "Indirizzo"
    },
    {
        key: "city",
        name: "Città"
    },
    {
        key: "phone",
        name: "Telefono"
    },
    {
        key: "date",
        name: "Data"
    },
    {
        key: "total",
        name: "Totale"
    },
]