import React from "react"

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const ModalProducts = ({ productName, productImage, productQuantity, productOption, productPrice = 0 }) => {
    return (
        <Card style={{ display: "flex", flexDirection: "row", margin: 16, justifyContent: 'space-between' }}>
            <div style={{ display: "flex", flexDirection: "column", width: "35%" }}>
                <CardContent style={{ flex: "1 0 auto", textAlign: 'center' }}>
                    <Typography component="h5" variant="h5">{productName}</Typography>
                    {
                        productOption !== undefined &&
                        <Typography variant="subtitle1" color="textSecondary">{productOption}</Typography>
                    }
                    <Typography variant="subtitle1" color="textSecondary">Quantità: {productQuantity}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">{productPrice.toFixed(2)} €</Typography>
                </CardContent>
            </div>
            <CardMedia
                style={{ width: "65%", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: 150, marginTop: "auto", marginBottom: "auto" }}
                image={productImage}
                title={productName}
            />
        </Card>
    )
}

export default ModalProducts