import React from "react"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from '@material-ui/core/Grid'

import TableOrders from "./TableOrders"

const OrderStatus = ({ name, ACTIVITY_ID }) => {

    return (
        <Container style={{ margin: 16 }}>
            <Grid container justify="space-between" alignItems="center">
                <Typography variant="h4" component="h2" gutterBottom style={{ fontWeight: "bold" }}>{name}</Typography>
            </Grid>
            <Grid container style={{ marginBottom: 24 }}>
                <Typography variant="h5" component="h2" gutterBottom style={{ fontWeight: "bold" }}>Ordini in corso</Typography>
                <TableOrders ACTIVITY_ID={ACTIVITY_ID} />
            </Grid>
            <Grid container style={{ marginBottom: 24 }}>
                <Typography variant="h5" component="h2" gutterBottom style={{ fontWeight: "bold" }}>Ordini consegnati</Typography>
                <TableOrders delivered ACTIVITY_ID={ACTIVITY_ID} />
            </Grid>
        </Container>
    )
}

export default OrderStatus