import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';

import { Link } from "react-router-dom"

import Cookies from "universal-cookie"

import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { profile } from "../utils/labels.json"

const cookies = new Cookies()
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    color: {
        color: '#fff'
    },
    margin: {
        marginRight: 6
    }
}));

export const Header = ({ onClick, funzioni, onClose, state }) => {

    const [isOpen, setOpen] = useState(false)

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const NoStyleLink = ({ to, children, onClick = undefined }) => <Link to={to} onClick={onClick} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }} >
        {children}
    </Link>

    const handleLogout = () => {
        localStorage.removeItem('ACCESS_TOKEN', null)
        localStorage.removeItem('ACTIVITY_ID_TOKEN', null)
        cookies.remove('ACCESS_TOKEN', null, { path: '/' })
        cookies.remove('ACTIVITY_ID_TOKEN', null, { path: '/' })
        window.open('/login', '_self')
    }

    const list = () => (
        <div
            style={{ width: 300 }}
            role="presentation"
            onClick={onClose}
            onKeyDown={onClose}
        >
            <List style={{ marginTop: 56 }} >
                {funzioni.map(({ key, name, icon, to }) => (
                    <Link to={to} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>
                        <ListItem button key={key.toString()} >
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={name} />
                        </ListItem>
                        <Divider />
                    </Link>
                ))}
            </List>

        </div>
    );


    return (
        <>
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => setOpen(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <Link /* to={"/"} */ style={{ textDecoration: "none", color: "white" }}>
                                AppMyBusiness
                        </Link>
                        </Typography>
                        <div>
                            <Button
                                variant="outlined"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                className={classes.color}
                                onClick={handleMenu}
                            >
                                <AccountCircle className={classes.margin} />
                                {profile.hero}
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <NoStyleLink to={"/generals"} >
                                    <MenuItem onClick={handleClose}>{profile.generals}</MenuItem>
                                </NoStyleLink>
                                <NoStyleLink to={"/settings"} >
                                    <MenuItem onClick={handleClose}>{profile.settings}</MenuItem>
                                </NoStyleLink>
                                <Divider />
                                <NoStyleLink to={"/help"}>
                                    <MenuItem onClick={handleClose}>{profile.help}</MenuItem>
                                </NoStyleLink>
                                <NoStyleLink to={"/feedback"}>
                                    <MenuItem onClick={handleClose}>{profile.feedback}</MenuItem>
                                </NoStyleLink>
                                <Divider />
                                <NoStyleLink onClick={() => handleLogout()}>
                                    <MenuItem onClick={handleClose}>{profile.logout}</MenuItem>
                                </NoStyleLink>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            </div >
            <div>
                <Drawer open={isOpen} onClose={() => setOpen(false)}>
                    {list()}
                </Drawer>
            </div>
        </>
    );
}