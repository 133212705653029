import { useState, useEffect } from "react"

import { useQuery, gql } from '@apollo/client'

const FETCH_CATEGORIES = gql`
    query ($type: ID!, $activity: ID!) {
        categories (where: { category_type: $type, activity: $activity } ) {
            id
            CategoryName
            Logo { url }
            Disabled
        }
    }
`

export const useCategories = (type, activity) => {
    const [categories, setCategories] = useState([])

    const categories_fetch = useQuery(FETCH_CATEGORIES, {
        variables: {
            type: type,
            activity: activity
        }
    })

    useEffect(() => {
        let { data } = categories_fetch
        if (data) {
            let categoriesFetched = []
            data.categories.forEach(({ id, CategoryName, Disabled, Logo: { url } }) => {
                if (!Disabled) categoriesFetched.push({ id: id, name: CategoryName, logo: url })
            })
            setCategories(categoriesFetched)
        }

    }, [categories_fetch])

    return categories
}