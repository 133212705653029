import { useMutation, gql } from '@apollo/client'

const LOGIN_QUERY = gql`
mutation($email: String!, $psw: String!) {
    login(input: { identifier: $email, password: $psw }) {
      jwt
      user {
        id
        email
        isFirstAccess
      }
    }
}  
`

export const useLogin = () => {

    const [initMutation] = useMutation(LOGIN_QUERY)

    return [initMutation]
}