import React from "react"
import Chip from '@material-ui/core/Chip';

import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

const AppChips = ({ os, onClick, ...props }) => {
    return (
        <Chip
            icon={os === "ios" ? <AppleIcon /> : <AndroidIcon />}
            label={os === "ios" ? "App Store" : "Play Store"}
            onClick={onClick}
            variant="outlined"
            {...props}
        />
    )
}

export default AppChips