import { useState, useEffect } from "react"

import { useQuery, gql } from '@apollo/client'

const FETCH_GENERAL_SETTINGS = gql`
query ($id: ID!) {
    user (id: $id) {
        Android
        iOS
        Spedition
        message
    }
}
`

export const useGenerals = (activity) => {
    const [settings, setSettings] = useState({
        android: null,
        ios: null,
        spedition: 0,
        message: ''
    })

    const settings_fetch = useQuery(FETCH_GENERAL_SETTINGS, {
        variables: {
            id: activity
        }
    })

    useEffect(() => {
        let { data } = settings_fetch
        if (data) {
            let { Android, iOS, Spedition, message } = data.user

            setSettings({
                android: Android,
                ios: iOS,
                spedition: Spedition,
                message: message
            })
        }

    }, [settings_fetch])

    return settings
}