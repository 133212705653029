import React, { useState, useEffect } from "react";

import { DropzoneArea } from "material-ui-dropzone";

import AddIcon from "@material-ui/icons/Add";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { useMutation } from "@apollo/client";

import { SET_PRODUCT, SET_IMAGE } from "../../utils/queries";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useRecoilValue } from "recoil";
import { CATEGRORIES_TYPE } from "../../recoil/atoms";

import { useCategories } from "../../hooks/useCategories";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const ProductUpload = () => {
  const type = useRecoilValue(CATEGRORIES_TYPE);

  const ACTIVITY_ID =
    localStorage.getItem("ACTIVITY_ID_TOKEN") ||
    cookies.get("ACTIVITY_ID_TOKEN");

  const categories = useCategories(type, ACTIVITY_ID);

  const [name, setName] = useState("");
  const [file, setFile] = useState([]);
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [isRecommended, setIsRecommended] = useState(false);

  const [optionPrice, setOptionPrice] = useState("");
  const [optionValue, setOptionValue] = useState("");
  const [options, setOptions] = useState([]);

  const [isOpenMessage, setOpenMessage] = useState(false);

  const [imageId, setImageId] = useState("");

  const [isDisabled, setDisabled] = useState(true);

  const [setImageDatabase, imageResult] = useMutation(SET_IMAGE, {
    variables: {
      file: file,
    },
  });

  const [setProductDatabase] = useMutation(SET_PRODUCT, {
    variables: {
      name: name,
      image: imageId,
      description: description,
      price: price,
      category: category,
      options: options,
    },
  });

  const handleInsertOptions = () => {
    let newOption = {
      name: optionValue || "",
      price: optionPrice || 0,
    };
    setOptionValue("");
    setOptionPrice("");
    setOptions([...options, newOption]);
  };

  const handleRemove = (index) => {
    let tempOptions = JSON.parse(JSON.stringify(options));
    tempOptions.splice(index, 1);
    setOptions(tempOptions);
  };

  useEffect(() => {
    let { data } = imageResult;
    if (data) {
      let id = data.upload.id;
      setImageId(id);
    }
  }, [imageResult]);

  const handleInsert = () => {
    if (file.length === 0) {
      setProductDatabase({
        variables: {
          name: name,
          image: "60409cc097a86c64bd6e4096",
          description: description,
          price: price,
          category: category,
          options: options,
          isRecommended: isRecommended,
        },
      })
        .then(() => window.location.reload())
        .catch(() => {
          setOpenMessage(true);
        });
    } else {
      setImageDatabase()
        .then((val) => {
          setProductDatabase({
            variables: {
              name: name,
              image: val.data.upload.id,
              description: description,
              price: price,
              category: category,
              options: options,
              isRecommended: isRecommended,
            },
          })
            .then(() => window.location.reload())
            .catch(() => {
              setOpenMessage(true);
            });
        })
        .catch(() => {
          setOpenMessage(true);
        });
    }
  };

  useEffect(() => {
    setDisabled(
      name.trim() === "" ||
        category.trim() === "" ||
        description.trim() === "" ||
        price === ""
    );
  }, [name, price, description, options, category]);

  const handleRecommendedChange = (event) =>
    setIsRecommended(event.target.checked);

  return (
    <>
      <Snackbar
        open={isOpenMessage}
        autoHideDuration={900}
        onClose={() => setOpenMessage(false)}
        message={"Ci sono stati errori, riprova più tardi."}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />
      <List>
        <ListItem>
          <DropzoneArea
            maxFileSize={5000000}
            useChipsForPreview
            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
            dropzoneText="Trascina l'immagine o clicca qui (max. 2MB):"
            onChange={(uploadedFile) =>
              uploadedFile.length !== 0 && setFile(uploadedFile[0])
            }
            filesLimit={1}
            onDelete={() => setFile([])}
          />
        </ListItem>
        <ListItem>
          <TextField
            style={{ margin: 8 }}
            label="Nome prodotto"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            style={{ margin: 8 }}
            label="Prezzo prodotto"
            type="number"
            variant="outlined"
            value={price}
            onChange={(e) =>
              e.target.value === ""
                ? setPrice("")
                : setPrice(e.target.valueAsNumber)
            }
            inputProps={{ step: "0.1", min: "0" }}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <TextField
            style={{ margin: 8 }}
            label="Descrizione prodotto"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            style={{ margin: 8, width: "100%" }}
            control={
              <Checkbox
                checked={isRecommended}
                onChange={handleRecommendedChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Consigliato"
          />
        </ListItem>
        <ListItem>
          <Box style={{ margin: 8, maxWidth: "50%", width: "100%" }}>
            <InputLabel htmlFor="standard-adornment">
              Voci aggiuntive (taglie, pesi, colori, ecc.)
            </InputLabel>
            <Box style={{ display: "flex", margin: 8 }}>
              <TextField
                style={{ marginLeft: 8, marginRight: 8 }}
                label="Tipologia"
                value={optionValue}
                onChange={(e) => setOptionValue(e.target.value)}
              />
              <TextField
                style={{ marginLeft: 8, marginRight: 8 }}
                label="Prezzo"
                type="number"
                value={optionPrice}
                onChange={(e) =>
                  e.target.value === ""
                    ? setOptionPrice("")
                    : setOptionPrice(e.target.valueAsNumber)
                }
                inputProps={{ step: "0.1", min: "0" }}
              />
              <IconButton
                onClick={() => handleInsertOptions()}
                disabled={
                  optionValue.length === 0 ||
                  optionPrice.toString().length === 0
                    ? true
                    : false
                }
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Box style={{ padding: 8, display: "flex", overflowX: "auto" }}>
              {options.map(({ name, price }, index) => (
                <Box style={{ margin: 8 }}>
                  <Chip
                    key={index}
                    label={`${name} - ${price.toFixed(2)}€`}
                    onDelete={() => handleRemove(index)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <Box style={{ width: "100%", margin: 8 }}>
            <InputLabel>Categoria</InputLabel>
            <Select
              style={{ margin: 8 }}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label="Categoria"
              fullWidth
            >
              {categories.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </Box>
        </ListItem>
        <Box
          style={{ margin: 16, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<SaveIcon />}
            disabled={isDisabled}
            onClick={() => handleInsert()}
          >
            Salva
          </Button>
        </Box>
      </List>
    </>
  );
};

export default ProductUpload;
