import { useState, useEffect } from "react";

import { useQuery, gql } from "@apollo/client";

const FETCH_PRODUCTS = gql`
  query($type: ID!, $activity: ID!) {
    products(
      where: { category: { activity: $activity, category_type: $type } }
    ) {
      id
      Image {
        url
      }
      Name
      Description
      Price
      category {
        id
        CategoryName
      }
      IsInOffer
      OfferExpiration
      OfferPrice
      Disabled
      options
      isRecommended
    }
  }
`;

export const useProducts = (type, activity) => {
  const [products, setProducts] = useState([]);

  const products_fetch = useQuery(FETCH_PRODUCTS, {
    variables: {
      type: type,
      activity: activity,
    },
  });

  useEffect(() => {
    let { data } = products_fetch;
    if (data) {
      let productsFetched = [];
      data.products.forEach(
        ({
          id,
          Disabled,
          Image,
          Name,
          Description,
          Price,
          IsInOffer,
          OfferExpiration,
          OfferPrice,
          category,
          options,
          isRecommended,
        }) => {
          if (!Disabled)
            productsFetched.push({
              id: id,
              image: Image.url,
              name: Name,
              inOffer: IsInOffer && new Date(OfferExpiration) >= new Date(),
              price: Price,
              category: category,
              offerPrice: OfferPrice,
              description: Description,
              offerExpiration: OfferExpiration,
              options: options,
              isRecommended: isRecommended,
            });
        }
      );
      setProducts(productsFetched);
    }
  }, [products_fetch]);

  return products;
};
