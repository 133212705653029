import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";

import { DropzoneArea } from "material-ui-dropzone";

import { useMutation } from "@apollo/client";

import { SET_CATEGORY, SET_IMAGE } from "../../utils/queries";

import { useRecoilValue } from "recoil";
import { CATEGRORIES_TYPE } from "../../recoil/atoms";

import Cookies from "universal-cookie";

const DATA = [];
const cookies = new Cookies();
const CategoryUpload = () => {
  const ACTIVITY_ID =
    localStorage.getItem("ACTIVITY_ID_TOKEN") ||
    cookies.get("ACTIVITY_ID_TOKEN");

  const type = useRecoilValue(CATEGRORIES_TYPE);

  const [name, setName] = useState("");
  const [file, setFile] = useState([]);
  const [imageId, setImageId] = useState("");

  const [isDisabled, setDisabled] = useState(true);
  const [isOpenMessage, setOpenMessage] = useState(false);

  const [setImageDatabase, imageResult] = useMutation(SET_IMAGE, {
    variables: {
      file: file,
    },
  });

  const [setCategoryDatabase] = useMutation(SET_CATEGORY, {
    variables: {
      name: name,
      image: imageId,
      type: type,
      activity: ACTIVITY_ID,
    },
  });

  useEffect(() => {
    let { data } = imageResult;
    if (data) {
      let id = data.upload.id;
      setImageId(id);
    }
  }, [imageResult]);

  const handleInsert = () => {
    setImageDatabase()
      .then((val) => {
        setCategoryDatabase({
          variables: {
            name: name,
            image: val.data.upload.id,
            type: type,
            activity: ACTIVITY_ID,
          },
        })
          .then(() => window.location.reload())
          .catch(() => {
            setOpenMessage(true);
          });
      })
      .catch(() => {
        setOpenMessage(true);
      });
  };

  useEffect(() => {
    setDisabled(name.trim() === "" || (imageId === "" && file.length === 0));
  }, [name, imageId, file]);

  return (
    <>
      <Snackbar
        open={isOpenMessage}
        autoHideDuration={300}
        onClose={() => setOpenMessage(false)}
        message={"Ci sono stati errori, riprova più tardi."}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />
      <List>
        <ListItem>
          <TextField
            id="outlined-basic"
            label="Nome categoria"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </ListItem>
        <ListItem>
          <Box>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              style={{ fontWeight: "400" }}
            >
              Immagine categoria (max. 2MB)
            </Typography>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                overflow: "hidden",
              }}
            >
              <GridList cols={4} style={{ flexWrap: "nowrap" }}>
                {DATA.map(({ src, alt }, index) => (
                  <GridListTile key={index}>
                    <img src={src} alt={alt} />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </Box>
        </ListItem>
        <ListItem>
          <DropzoneArea
            maxFileSize={5000000}
            filesLimit={1}
            useChipsForPreview
            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
            dropzoneText="Trascina l'immagine o clicca qui:"
            onChange={(uploadedFile) =>
              uploadedFile.length !== 0 && setFile(uploadedFile[0])
            }
            onDelete={() => setFile([])}
          />
        </ListItem>
        <Box
          style={{ margin: 16, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<SaveIcon />}
            disabled={isDisabled}
            onClick={() => handleInsert()}
          >
            Salva
          </Button>
        </Box>
      </List>
    </>
  );
};

export default CategoryUpload;
