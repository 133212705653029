import React from 'react'

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import SaveIcon from "@material-ui/icons/Save"

export const SaveButton = ({ text, onClick }) => {
    return (
        <Box style={{ marginTop: 32, display: "flex", justifyContent: "flex-end" }} >
            <Button
                variant="contained"
                color="primary"
                size="large"
                endIcon={<SaveIcon />}
                onClick={onClick}
            >
                {text}
            </Button>
        </Box>
    )
}