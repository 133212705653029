import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import CloseIcon from '@material-ui/icons/Close';

import CategoryUpload from "./CategoryUpload"
import CategoryToEdit from './CategoryToEdit';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCategory = ({ handleClose, isOpen, edit }) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} color={edit ? "secondary" : "primary"}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>{edit ? "Modifica" : "Aggiungi"}</Typography>
                        <Button autoFocus color="inherit" onClick={handleClose} >Annulla</Button>
                    </Toolbar>
                </AppBar>
                {
                    edit ? <CategoryToEdit /> : <CategoryUpload />
                }
            </Dialog>
        </div>
    );
}

export default ModalCategory