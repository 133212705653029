import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

import SaveIcon from "@material-ui/icons/Save";

import Account from "../components/Account";
import PasswordInput from "../components/PasswordInput";

import { SET_PK, EDIT_PSW } from "../utils/queries";

import { useMutation } from "@apollo/client";
import Cookies from "universal-cookie";

import axios from "axios";

import { settings } from "../utils/labels.json";

const cookies = new Cookies();
const Settings = () => {
  const ACTIVITY_ID =
    localStorage.getItem("ACTIVITY_ID_TOKEN") ||
    cookies.get("ACTIVITY_ID_TOKEN");

  const [setPublic] = useMutation(SET_PK);
  const [setPsw] = useMutation(EDIT_PSW);

  const [isPasswordValid, setPasswordValid] = useState(true);

  const [curPassword, setCurPassword] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const [isErrorPublic, setErrorPublic] = useState(false);
  const [isSuccessPublic, setSuccessPublic] = useState(false);

  const [isErrorPrivate, setErrorPrivate] = useState(false);
  const [isSuccessPrivate, setSuccessPrivate] = useState(false);

  const [isPasswordError, setPasswordError] = useState(false);
  const [isPasswordSuccess, setPasswordSuccess] = useState(false);

  const PaddedAlert = ({ text, type, condition }) =>
    condition && (
      <div style={{ margin: 8 }}>
        <Alert severity={type}>{text}</Alert>
      </div>
    );

  useEffect(() => {
    if (
      password.length >= 6 &&
      password === confirmPassword &&
      curPassword.trim() !== ""
    )
      setPasswordValid(true);
    else setPasswordValid(false);
  }, [password, confirmPassword, curPassword]);

  const SaveButton = ({ disabled = false, onClick }) => (
    <Box style={{ marginTop: 32, display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        endIcon={<SaveIcon />}
        disabled={disabled}
        onClick={onClick}
      >
        Salva
      </Button>
    </Box>
  );

  const handleEditPsw = (password) => {
    setPsw({ variables: { key: password, user: ACTIVITY_ID } })
      .then(() => {
        setPassword("");
        setConfirmPassword("");
        setCurPassword("");
        setPasswordError(false);
        setPasswordSuccess(true);
      })
      .catch(() => {
        setPasswordSuccess(false);
        setPasswordError(true);
      });
  };

  const handlePublicKey = (publicKey) => {
    setPublic({ variables: { key: publicKey, activity: ACTIVITY_ID } })
      .then(() => {
        setPublicKey("");
        setSuccessPublic(true);
        setErrorPublic(false);
      })
      .catch(() => {
        setSuccessPublic(false);
        setErrorPublic(true);
      });
  };

  const handlePrivateKey = (privateKey) => {
    axios
      .post("https://cms.appmybusiness.eu/pay/setSK", {
        sk: privateKey,
        id: ACTIVITY_ID,
      })
      .then(() => {
        setPrivateKey("");
        setSuccessPrivate(true);
        setErrorPrivate(false);
      })
      .catch(() => {
        setSuccessPrivate(false);
        setErrorPrivate(true);
      });
  };

  return (
    <Account name={settings.title}>
      <Box style={{ margin: 8 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {settings.password.subtitle}
        </Typography>
        <PasswordInput
          value={curPassword}
          onChange={(e) => setCurPassword(e.target.value)}
          placeholder={settings.password.curPas}
        />
        <Grid style={{ marginBottom: 8, marginTop: 8 }} container spacing={3}>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={settings.password.newPas}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={settings.password.confPas}
            />
          </Grid>
        </Grid>
        <PaddedAlert
          type={"success"}
          text={settings.password.success}
          condition={isPasswordSuccess}
        />
        <PaddedAlert
          type={"error"}
          text={settings.password.errors}
          condition={isPasswordError}
        />
        <PaddedAlert
          type={"warning"}
          text={settings.password.pattern}
          condition={password.length < 6}
        />
        <PaddedAlert
          type={"warning"}
          text={settings.password.warning}
          condition={password !== confirmPassword}
        />

        <SaveButton
          onClick={() => handleEditPsw(password)}
          disabled={!isPasswordValid}
        />
      </Box>
      <Box style={{ margin: 8 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {settings.stripe.subtitle}
        </Typography>
        <div>{settings.stripe.caption}</div>
        <div>{settings.stripe.secondCaption}</div>
        <Box>
          <PasswordInput
            placeholder={settings.stripe.placeholderPub}
            value={publicKey}
            onChange={(e) => setPublicKey(e.target.value)}
          />
          <SaveButton
            onClick={() => handlePublicKey(publicKey)}
            disabled={publicKey.length === 0}
          />
          <PaddedAlert
            type={"success"}
            text={settings.stripe.success}
            condition={isSuccessPublic}
          />
          <PaddedAlert
            type={"error"}
            text={settings.stripe.errors}
            condition={isErrorPublic}
          />
        </Box>
        <Box>
          <PasswordInput
            placeholder={settings.stripe.placeholderPriv}
            value={privateKey}
            onChange={(e) => setPrivateKey(e.target.value)}
          />
          <SaveButton
            onClick={() => handlePrivateKey(privateKey)}
            disabled={privateKey.length === 0}
          />
          <PaddedAlert
            type={"success"}
            text={settings.stripe.success}
            condition={isSuccessPrivate}
          />
          <PaddedAlert
            type={"error"}
            text={settings.stripe.errors}
            condition={isErrorPrivate}
          />
        </Box>
      </Box>
    </Account>
  );
};

export default Settings;
