import React, { useState } from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from "@material-ui/core/Box";

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { useMutation, gql } from '@apollo/client'

const INSERT_OFFER = gql`
mutation(
    $id: ID!
    $offerPrice: Float!
    $offerExpiration: Date!
) {
    updateProduct(
    input: {
        where: { id: $id }
        data: {
            IsInOffer: true
            OfferPrice: $offerPrice
            OfferExpiration: $offerExpiration
        }
    }
    ) {
    product {
        Name
    }
    }
}
`

const NewOffer = ({ handleClose, isOpen, products }) => {

    const [offerDate, setOfferDate] = useState(new Date())
    const [id, setId] = useState('')
    const [price, setPrice] = useState('')

    const [setOffer,] = useMutation(INSERT_OFFER, {
        variables: { id: id, offerExpiration: offerDate, offerPrice: price }
    })

    const handleInsert = () => {
        setOffer()
            .then((value) => window.location.reload())
            .catch(() => alert('Errore'))
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} >
            <DialogTitle>Nuova offerta</DialogTitle>
            <DialogContent>
                <DialogContentText>Scegli il prodotto, la data e il prezzo dell'offerta!</DialogContentText>
                <Box style={{ margin: 8 }}>
                    <InputLabel >Prodotto</InputLabel>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                    >
                        {
                            products.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)
                        }
                    </Select>
                </Box>
                <Box style={{ margin: 8 }}>
                    <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        label="Scadenza offerta"
                        value={offerDate}
                        onChange={(date) => setOfferDate(`${date.getFullYear()}-${date.getMonth() + 1 <= 9 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() <= 9 ? '0' : ''}${date.getDate()}`)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Box>
                <Box style={{ margin: 8 }}>
                    <TextField label="Prezzo prodotto" type="number" variant="standard" value={price} onChange={(e) => e.target.value === '' ? setPrice('') : setPrice(e.target.valueAsNumber)} inputProps={{ step: "0.1", min: "0" }} fullWidth />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Chiudi
          </Button>
                <Button onClick={() => handleInsert()} color="primary">
                    Inserisci
          </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewOffer