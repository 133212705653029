import { useState, useEffect } from "react"

import { useQuery, gql } from '@apollo/client'

const FETCH_ORDERS = gql`
query($activity: ID!) {
  orders(where: { activity: $activity, status_ne: "C" }) {
    id
    Name
    Address
    City
    Phone
    Price
    status
    pickup
    OrderId
    createdAt
    notes
    pickupDay
    orders_details {
      quantity
      option
      product {
        Name
        Price
        Image {
          url
        }
      }
    }
  }
} 
`

const FETCH_ORDERS_DELIVERED = gql`
query($activity: ID!) {
  orders(where: { activity: $activity, status: "C" }) {
    id
    Name
    Address
    City
    Phone
    pickup
    Price
    status
    OrderId
    pickupDay
    createdAt
    notes
    orders_details {
      quantity
      option
      product {
        Name
        Price
        Image {
          url
        }
      }
    }
  }
}
`

export const useOrders = (type = false, activity) => {
  const [orders, setOrders] = useState([])

  const orders_fetch = useQuery((type ? FETCH_ORDERS_DELIVERED : FETCH_ORDERS), {
    variables: {
      activity: activity
    }
  })

  useEffect(() => {
    let { data } = orders_fetch
    if (data) {
      let ordersFetched = []
      data.orders.forEach(({ id, Name, Address, City, Phone, Price, status, createdAt, OrderId, orders_details, pickup, pickupDay, notes }) => {
        let order = {
          id: id,
          name: Name,
          address: Address,
          city: City,
          phone: Phone,
          total: Price.toFixed(2),
          status: status,
          createdAt: createdAt,
          orderId: OrderId,
          orders_details: orders_details,
          pickup: pickup,
          pickupDay: pickupDay,
          notes: notes
        }
        ordersFetched.push(order)
      })
      setOrders(ordersFetched)
    }

  }, [orders_fetch])

  return orders
}