import { gql } from "@apollo/client";

export const SET_PRODUCT = gql`
  mutation(
    $name: String!
    $image: ID
    $description: String!
    $price: Float!
    $category: ID!
    $options: JSON!
    $isRecommended: Boolean!
  ) {
    createProduct(
      input: {
        data: {
          Name: $name
          Image: $image
          Description: $description
          Price: $price
          category: $category
          options: $options
          isRecommended: $isRecommended
        }
      }
    ) {
      product {
        Name
      }
    }
  }
`;

export const SET_ECOMMERCE = gql`
  mutation($id: ID!, $value: Boolean!) {
    updateUser(input: { where: { id: $id }, data: { ECommerce: $value } }) {
      user {
        ECommerce
      }
    }
  }
`;

export const SET_ORDINAZIONI = gql`
  mutation($id: ID!, $value: Boolean!) {
    updateUser(input: { where: { id: $id }, data: { Ordinazioni: $value } }) {
      user {
        Ordinazioni
      }
    }
  }
`;

export const SET_SPEDITION = gql`
  mutation($spedition: Float!, $id: ID!) {
    updateUser(input: { where: { id: $id }, data: { Spedition: $spedition } }) {
      user {
        Spedition
      }
    }
  }
`;

export const SET_MESSAGE = gql`
  mutation($activity: ID!, $message: String!) {
    updateUser(
      input: { where: { id: $activity }, data: { message: $message } }
    ) {
      user {
        message
      }
    }
  }
`;

export const GET_SPEDITION = gql`
  query($id: ID!) {
    user(id: $id) {
      Spedition
    }
  }
`;

export const SET_CATEGORY = gql`
  mutation($type: ID!, $activity: ID!, $name: String!, $image: ID!) {
    createCategory(
      input: {
        data: {
          CategoryName: $name
          activity: $activity
          category_type: $type
          Logo: $image
        }
      }
    ) {
      category {
        id
      }
    }
  }
`;

export const SET_SERVIZI = gql`
  mutation($id: ID!, $value: Boolean!) {
    updateUser(input: { where: { id: $id }, data: { Servizi: $value } }) {
      user {
        Servizi
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation($id: ID!, $name: String) {
    updateCategory(
      input: { where: { id: $id }, data: { CategoryName: $name } }
    ) {
      category {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation(
    $id: ID!
    $name: String
    $description: String
    $price: Float
    $category: ID
    $options: JSON
    $isRecommended: Boolean
  ) {
    updateProduct(
      input: {
        where: { id: $id }
        data: {
          Name: $name
          Description: $description
          Price: $price
          category: $category
          options: $options
          isRecommended: $isRecommended
        }
      }
    ) {
      product {
        Name
      }
    }
  }
`;

export const SET_IMAGE = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      id
    }
  }
`;

export const SET_PK = gql`
  mutation($key: String!, $activity: ID!) {
    updateUser(
      input: { where: { id: $activity }, data: { publicStrapiKey: $key } }
    ) {
      user {
        publicStrapiKey
      }
    }
  }
`;

export const SET_SK = gql`
  mutation($key: String!, $activity: ID!) {
    updateUser(
      input: { where: { id: $activity }, data: { privateStrapiKey: $key } }
    ) {
      user {
        privateStrapiKey
      }
    }
  }
`;

export const EDIT_PSW = gql`
  mutation($key: String!, $user: ID!) {
    updateUser(
      input: {
        where: { id: $user }
        data: { password: $key, isFirstAccess: false }
      }
    ) {
      user {
        id
      }
    }
  }
`;
