import React, { useState, useEffect } from "react"
import Typography from "@material-ui/core/Typography"
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from "@material-ui/core/Container"
import Grid from '@material-ui/core/Grid'

import CategoryTable from "./categories/TableCategory"
import ProductTable from "./products/TableProduct"

import { CATEGORY_COLS, PRODUCT_COLS } from "../utils/columns"

import { useQuery, gql, useMutation } from '@apollo/client'

import { SET_ECOMMERCE, SET_ORDINAZIONI, SET_SERVIZI } from "../utils/queries"

import { useRecoilState } from "recoil"
import { CATEGRORIES_TYPE } from "../recoil/atoms"

import { useCategories } from "../hooks/useCategories"

import { useProducts } from "../hooks/useProducts"

const FunctionPage = ({ name, functionType, ACTIVITY_ID }) => {

    const [, setCategoryType] = useRecoilState(CATEGRORIES_TYPE)

    useEffect(() => {
        setCategoryType(functionType)
    }, [functionType])

    const categories = useCategories(functionType, ACTIVITY_ID)
    const products = useProducts(functionType, ACTIVITY_ID)

    const CATEGORIES_QUERY = gql`
        query($id: ID!) {
            user(id: $id) {
                ${name}
            }
        }
    `

    const queryGet = useQuery(CATEGORIES_QUERY, { variables: { id: ACTIVITY_ID } })

    const STATE_QUERY = name === "ECommerce" ? SET_ECOMMERCE : name === "Ordinazioni" ? SET_ORDINAZIONI : name === "Servizi" && SET_SERVIZI

    const [functionState, setFunctionState] = useState(false)

    const [setFunctionStateDatabase, queryUpdate] = useMutation(STATE_QUERY, {
        variables: {
            id: ACTIVITY_ID,
            value: !functionState
        }
    })

    useEffect(() => {
        let { data } = queryGet
        if (data) {
            let state = data.user[name]
            setFunctionState(state)
        }
    }, [queryGet])

    useEffect(() => {
        let { data } = queryUpdate
        if (data) {
            let newState = data.updateUser.user[name]
            setFunctionState(newState)
        }
    }, [queryUpdate])

    const handleStateChange = (value) => {
        setFunctionState(value)
        if (value !== functionState) {
            setFunctionStateDatabase()
        }
    }

    return (
        <Container style={{ margin: 16 }}>
            <Grid container justify="space-between" alignItems="center">
                <Typography variant="h4" component="h2" gutterBottom style={{ fontWeight: "bold" }}>{name}</Typography>
                <ButtonGroup size="large" color="primary">
                    <Button variant={functionState ? "contained" : "outlined"} onClick={() => handleStateChange(true)} >Attivo</Button>
                    <Button variant={functionState ? "outlined" : "contained"} onClick={() => handleStateChange(false)} >Disattivo</Button>
                </ButtonGroup>
            </Grid>
            <CategoryTable tableName={"Categorie"} columns={CATEGORY_COLS} rows={categories} />
            <ProductTable tableName={"Prodotti"} columns={PRODUCT_COLS} rows={products} />
        </Container>
    )
}

export default FunctionPage