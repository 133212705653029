import React, { useState } from "react"

import Box from "@material-ui/core/Box"
import Grid from '@material-ui/core/Grid'
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import SendIcon from '@material-ui/icons/Send'

import Account from "../components/Account"

import { help } from "../utils/labels.json"

const Help = () => {

    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')

    return (
        <Account name={help.title} >
            <Box style={{ margin: 8 }}>
                <Typography variant="h6" component="h2" gutterBottom style={{ fontWeight: "400" }}>
                    {help.subtitle}
                    <Typography variant="h6" component="h2" gutterBottom style={{ fontWeight: "300", color: "rgba(0,0,0,0.7)" }}>{help.caption}</Typography>
                </Typography>

                <Grid container spacing={2} style={{ marginTop: 16 }}>
                    <Grid item xs={12}>
                        <TextField required id="standard-required" variant="outlined" label={help.placeholderSubject} maxLength={76} fullWidth value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            label={help.placeholderMessage}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            required
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box style={{ marginTop: 16, display: "flex", justifyContent: "flex-end" }} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        endIcon={<SendIcon />}
                        href={`mailto:dev@appmybusiness.eu?subject=HAMB${subject}&body=${body}`}
                    >
                        Invia
                    </Button>
                </Box>
            </Box>
        </Account>
    )
}

export default Help