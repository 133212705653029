import React, { useState } from "react";

import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";

import noData from "../../assets/noData.png";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { useRecoilState } from "recoil";
import { PRODUCT_VALUES } from "../../recoil/atoms";

import ModalProduct from "./ModalProduct";
import NewOffer from "./NewOffer";

import { useMutation, gql } from "@apollo/client";

export const REMOVE_PRODUCT = gql`
  mutation($id: ID!) {
    updateProduct(input: { where: { id: $id }, data: { Disabled: true } }) {
      product {
        id
      }
    }
  }
`;

const ProductTable = ({ columns, rows, tableName }) => {
  const [isOpenModalAdd, setOpenModalAdd] = useState(false);
  const [isOpenModalEdit, setOpenModalEdit] = useState(false);
  const [isOpenModalOffer, setOpenModalOffer] = useState(false);
  const [id, setId] = useState("");

  const [, setProductValues] = useRecoilState(PRODUCT_VALUES);

  const [deleteProduct] = useMutation(REMOVE_PRODUCT, {
    variables: { id: id },
  });

  const handleDeleteProduct = (id) => {
    setId(id);
    setTimeout(() => {
      deleteProduct()
        .then(() => window.location.reload())
        .catch((error) => console.log(error));
    }, 300);
  };

  const handleEditProduct = (value, product) => {
    setProductValues(product);
    setOpenModalEdit(value);
  };

  return (
    <>
      <Container style={{ margin: 16 }}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 24 }}
        >
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            {tableName}
          </Typography>
          <Box>
            <Fab
              style={{ marginRight: 8 }}
              color="primary"
              variant="extended"
              aria-label="add"
              size="medium"
              onClick={() => setOpenModalOffer(true)}
            >
              <AddIcon />
              Nuova offerta
            </Fab>
            <Fab
              style={{ marginLeft: 8 }}
              color="primary"
              variant="extended"
              aria-label="add"
              size="medium"
              onClick={() => setOpenModalAdd(true)}
            >
              <AddIcon />
              Nuovo prodotto
            </Fab>
          </Box>
        </Grid>
        <NewOffer
          isOpen={isOpenModalOffer}
          handleClose={() => setOpenModalOffer(false)}
          products={rows}
        />
        <ModalProduct
          isOpen={isOpenModalAdd}
          handleClose={() => setOpenModalAdd(false)}
        />
        <ModalProduct
          isOpen={isOpenModalEdit}
          handleClose={() => setOpenModalEdit(false)}
          edit
        />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {[
                  ...columns,
                  { key: "edit", name: "Modifica" },
                  { key: "delete", name: "Elimina" },
                ].map(({ name, key }) => (
                  <>
                    <TableCell key={key}>{name}</TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <img src={noData} width={300} height={300} alt="Dati vuoti" />
                </TableRow>
              ) : (
                rows.map(
                  (
                    {
                      id,
                      name,
                      price,
                      image,
                      category,
                      inOffer,
                      offerExpiration,
                      offerPrice,
                      options,
                      description,
                      isRecommended,
                    },
                    index
                  ) => {
                    let product = {
                      id,
                      name,
                      price,
                      image,
                      category,
                      inOffer,
                      offerExpiration,
                      offerPrice,
                      options,
                      description,
                      isRecommended,
                    };
                    return (
                      <TableRow key={id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Avatar src={`http://cms.appmybusiness.eu${image}`} />
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{price.toFixed(2)} €</TableCell>
                        <TableCell>{category.CategoryName}</TableCell>
                        <TableCell>{inOffer ? "SI" : "NO"}</TableCell>
                        <TableCell>{offerExpiration}</TableCell>
                        <TableCell>{offerPrice}</TableCell>
                        <TableCell>
                          <Fab
                            color="default"
                            aria-label="edit"
                            size="small"
                            onClick={() => handleEditProduct(true, product)}
                          >
                            <EditIcon />
                          </Fab>
                        </TableCell>
                        <TableCell>
                          <Fab
                            color="default"
                            aria-label="edit"
                            size="small"
                            onClick={() => handleDeleteProduct(id)}
                          >
                            <DeleteIcon />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default ProductTable;
