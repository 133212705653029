import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import logo from "../assets/logo.svg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useLogin } from "../hooks/useLogin";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Alert from "@material-ui/lab/Alert";

import Cookies from "universal-cookie";

import { useRecoilState } from "recoil";
import { JWT as JWTAtom } from "../recoil/atoms";
import FirstAccessEdit from "../components/FirstAccessEdit";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Accedendo e registrandoti dichiari di aver preso visione ed accettare
      i&nbsp;
      <Link color="" href="https://www.onlineimpresa.it/CGC.pdf">
        termini e condizioni
      </Link>
      . I tuoi dati personali saranno trattati in conformità con la&nbsp;
      <Link
        color=""
        href="https://www.appmybusiness.eu/index.php/privacy-policy/"
      >
        privacy policy
      </Link>
      . Potrai formulare le tue preferenze circa il trattamento dei tuoi dati
      nella successiva schermata.
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.contrastText,
    width: 56,
    height: 56,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isEditPassword, setEditPassword] = useState(false);

  const [isRememberMeChecked, setRememberMe] = useState(false);

  const [isWrong, setError] = useState(false);
  const [isButtonDisabled, setDisabled] = useState(true);

  const [activityId, setActivityId] = useState("");

  const [jwt, setJwt] = useRecoilState(JWTAtom);

  const [login] = useLogin();

  useEffect(() => {
    setJwt(undefined);
  }, []);

  const handleLogin = (email, password) => {
    login({
      variables: {
        email: email,
        psw: password,
      },
    })
      .then((result) => {
        let {
          jwt,
          user: { id, isFirstAccess },
        } = result.data.login;
        if (isRememberMeChecked) {
          localStorage.setItem("ACCESS_TOKEN", jwt);
          localStorage.setItem("ACTIVITY_ID_TOKEN", id);
        } else {
          const cookies = new Cookies();
          cookies.set("ACCESS_TOKEN", jwt, { path: "/" });
          cookies.set("ACTIVITY_ID_TOKEN", id, { path: "/" });
        }
        setJwt(jwt);
        if (isFirstAccess) {
          setActivityId(id);
          setEditPassword(true);
        } else window.open("/status", "_self");
      })
      .catch((e) => {
        setError(true);
      });
  };

  useEffect(() => {
    if (isEmailValid(email) && password.length !== 0) setDisabled(false);
    else setDisabled(true);
  }, [email, password]);

  const isEmailValid = (email) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={logo} height={44} width={44} alt={"AppMyBusiness"} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Accedi su AppMyBusiness
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin(email, password);
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={isRememberMeChecked}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Ricordami"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            disabled={isButtonDisabled}
          >
            Entra
          </Button>
        </form>
      </div>
      {isWrong && <Alert severity="error">Email o password errate.</Alert>}
      <Box mt={8}>
        <Copyright />
      </Box>
      <Dialog open={isEditPassword} fullWidth={true}>
        <DialogTitle>Cambia password al primo accesso</DialogTitle>
        <DialogContent>
          <FirstAccessEdit ACTIVITY_ID={activityId} />
        </DialogContent>
      </Dialog>
    </Container>
  );
}
