import React, { useState, useEffect } from "react"

import Account from "../components/Account"
import AppChips from "../components/AppChips"

import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'

import { SaveButton } from "../components/SaveButton"

import { useMutation } from "@apollo/client"

import { useGenerals } from "../hooks/useGenerals"

import { SET_SPEDITION, SET_MESSAGE } from "../utils/queries"
import Cookies from "universal-cookie"

import { generals } from "../utils/labels.json"

const cookies = new Cookies()

const General = () => {
    const ACTIVITY_ID = localStorage.getItem('ACTIVITY_ID_TOKEN') || cookies.get('ACTIVITY_ID_TOKEN')

    const settings = useGenerals(ACTIVITY_ID)

    const [delivery, setDelivery] = useState(0)
    const [message, setMessage] = useState('')

    const [androidLink, setAndroidLink] = useState('')
    const [iosLink, setIosLink] = useState('')

    const [isOpenBackdrop, setOpenBackdrop] = useState(false)
    const [isOpenSnackbar, setOpenSnackbar] = useState(false)

    const [setDeliveryToDatabase, queryUpdateDelivery] = useMutation(SET_SPEDITION, { variables: { spedition: delivery, id: ACTIVITY_ID } })

    const [setMessageToDatabase, queryUpdateMessage] = useMutation(SET_MESSAGE, { variables: { id: ACTIVITY_ID } })

    useEffect(() => {
        let { data, error, loading } = queryUpdateDelivery
        if (loading) {
            setOpenBackdrop(true)
        }
        if (data) {
            setOpenBackdrop(false)
            let updatedDelivery = data.updateUser.user.Spedition
            setDelivery(updatedDelivery)
        }
        if (error) {
            setOpenSnackbar(true)
        }
    }, [queryUpdateDelivery])

    useEffect(() => {
        let { data, error, loading } = queryUpdateMessage
        if (loading) {
            setOpenBackdrop(true)
        }
        if (data) {
            setOpenBackdrop(false)
            let updatedMessage = data.updateUser.user.message
            setMessage(updatedMessage)
        }
        if (error) {
            setOpenSnackbar(true)
        }
    }, [queryUpdateMessage])

    useEffect(() => {
        let { android, ios, spedition, message } = settings

        setDelivery(spedition)

        setAndroidLink(android)
        setIosLink(ios)

        setMessage(message)

    }, [settings])

    const handleAppLink = (link) => {
        if (link === null)
            alert(`L'applicazione non è stata ancora pubblicata nella piattaforma scelta!`)
        else
            window.open(link)
    }

    const handleSaveMessage = (message) => {
        setMessageToDatabase({ variables: { activity: ACTIVITY_ID, message: message } })
            .then(() => window.location.reload())
            .catch((e) => console.log(e))
    }

    const handleSaveDelivery = () => {
        setDeliveryToDatabase()
            .then(() => window.location.reload())
            .catch((e) => console.log(e))
    }

    return (
        <>
            <Account name={generals.title}>
                <Backdrop open={isOpenBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    anchorOrigin={{ horizontal: "top", vertical: "center" }}
                    autoHideDuration={3000}
                    open={isOpenSnackbar}
                    message="Ci sono stati errori"
                    key={"topcenter"}
                />
                <Box style={{ margin: 8 }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography>{generals.store}</Typography>
                        <Box style={{ display: "flex", justifyContent: "space-around" }}>
                            <AppChips os={"ios"} onClick={() => handleAppLink(iosLink)} />
                            <AppChips os={"android"} onClick={() => handleAppLink(androidLink)} style={{ marginLeft: 16 }} />
                        </Box>
                    </Box>
                    <Box style={{ marginTop: 32 }}>
                        <TextField
                            id="delivery-price"
                            label={`${generals.delivery.label} ${(delivery === "" ? 0 : delivery).toFixed(2)}€`}
                            type="number"
                            style={{ width: "100%" }}
                            inputProps={{ step: "0.1", min: "0" }}
                            value={delivery}
                            onChange={(e) => setDelivery(e.target.value === "" ? "" : e.target.valueAsNumber)}
                        />
                        <SaveButton text={generals.delivery.save} onClick={() => handleSaveDelivery()} />
                    </Box>
                    <Box style={{ marginTop: 32 }} >
                        <TextField
                            id="delivery-price"
                            label={generals.message.label}
                            style={{ width: "100%" }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            maxLength={80}
                        />
                        <SaveButton text={generals.message.save} onClick={() => handleSaveMessage(message)} />
                    </Box>
                </Box>
            </Account>
        </>
    )
}

export default General